import styled from 'styled-components'

interface Props {
  appConfig?: any
}

export const StyledList = styled.ul<Props>`
  all: unset;

  li:nth-child(odd) {
    background-color: ${(props) => props.appConfig.majorColor};
  }
`
export const StyledEOD = styled.li`
  height: 9.3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-size: 20px;
`
