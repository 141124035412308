import styled from 'styled-components'

interface Props {
  appConfig?: any
}

export const StyledListingScreen = styled.div<Props>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.appConfig.textColor};
  font-family: inherit;
  box-sizing: border-box;
  height: 100vh;
  background-color: ${(props) => props.appConfig.minorColor};
`

export const StyledProgressWrapper = styled.div<Props>`
  background: ${(props) => props.appConfig.minorColor};
  font-size: 1.8vh;
  height: 3.4172vh;
  text-indent: 5.6vw;
`
