import React, { useState } from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { createDevApiEndpoints } from './mocks/dev-api-mock-endpoints'
import {
  isValidHearingRecord,
  isValidHearingRecordLobby,
  sortHearingsStrictAlphabetically,
  toUrlParams,
  validateUrlParams,
} from './util'
import { ErrorScreen } from './components/ErrorScreen/ErrorScreen'
import { ListingScreen } from './components/ListingScreen/ListingScreen'
import { CourtIdScreen } from './components/CourtIdScreen/CourtIdScreen'
import GlobalStyle from './theme/fontStyles'
import { LobbyScreen } from './components/LobbyScreen/LobbyScreen'
import { ConfigContext } from './context/appConfig.jsx'

const App = () => {
  const [searchParams] = useSearchParams()
  const errors = validateUrlParams(searchParams)
  const urlParams = toUrlParams(searchParams)

  if (urlParams.location === 'supremewcjc') {
    urlParams.location = 'wcjc'
  }

  if (process.env.NODE_ENV === 'development') {
    if (window.server) {
      window.server.shutdown()
    }
    createDevApiEndpoints()
  }

  const pruneData = (rawData: ApiResponseObject): HearingType[] => {
    const parsedData: HearingType[] = []

    if (!rawData || typeof rawData !== 'object' || !rawData.hearings) {
      return parsedData
    }

    if (Array.isArray(rawData.hearings)) {
      rawData.hearings.forEach((record) => {
        const formattedRecord = record
        if (formattedRecord.jurisdiction)
          formattedRecord.jurisdiction =
            formattedRecord.jurisdiction?.toLowerCase()

        if (isValidHearingRecord(formattedRecord)) {
          if (
            urlParams.screen === 'listing' &&
            urlParams.courtRoom
              ?.split(',')
              ?.includes(formattedRecord.courtRoom?.toLowerCase() as string)
          ) {
            parsedData.push(formattedRecord)
          }

          if (
            urlParams.screen === 'courtid' &&
            formattedRecord.courtRoom?.toLowerCase() === urlParams.courtRoom
          ) {
            Object.assign(formattedRecord, {
              timeString: `${formattedRecord.hearingDateTime}`,
            })
            parsedData.push(formattedRecord)
          }
        }
      })
    }

    if (Array.isArray(rawData.hearings)) {
      rawData.hearings.forEach((record) => {
        const formattedRecord = record
        if (formattedRecord.jurisdiction)
          formattedRecord.jurisdiction =
            formattedRecord.jurisdiction?.toLowerCase()

        if (isValidHearingRecordLobby(formattedRecord)) {
          if (
            urlParams.screen === 'lobby' &&
            ['cc', 'mcv', 'sc'].includes(formattedRecord.jurisdiction)
          ) {
            parsedData.push(formattedRecord)
          }
        }
      })
    }

    if (urlParams.screen === 'lobby') {
      return sortHearingsStrictAlphabetically(parsedData)
    }

    return parsedData
  }

  const appConfigQuery = useQuery(
    'csvw2',
    () =>
      axios
        .get(`${process.env.REACT_APP_CONFIG_URL}`, {
          headers: { source: 'Digital Display' },
        })
        .then((res) => {
          const defaultSettings = res.data.default.digitalDisplayContent
          const locationSettings =
            res.data[`${urlParams.location}`]?.digitalDisplayContent

          if (!locationSettings) {
            return defaultSettings
          }

          Object.keys(locationSettings).forEach((key: string) => {
            if (locationSettings[key] == null) {
              locationSettings[key] = defaultSettings[key]
            }
          })

          return locationSettings
        }),

    {
      enabled: !!errors && errors.length === 0,
    }
  )

  const { isLoading, data, isFetched, isFetching } = useQuery(
    'csvw',
    () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/?location=${
            urlParams.location &&
            urlParams.location.charAt(0).toUpperCase() +
              urlParams.location.slice(1)
          }&jurisdiction=sc,cc,mcv,chcv,vcat&sortBy=hearingDateTime`,
          { headers: { source: 'Digital display' } }
        )
        .then((res) => {
          return pruneData(res.data)
        }),
    {
      refetchInterval: Number(process.env.REACT_APP_REFRESH),
      enabled: !!errors && errors.length === 0,
      cacheTime: Number(process.env.REACT_APP_REFRESH),
    }
  )

  const hasLoaded =
    !isLoading &&
    isFetched &&
    !isFetching &&
    !errors.length &&
    !appConfigQuery.isLoading

  return (
    <div>
      <GlobalStyle />
      {errors && <ErrorScreen error={errors} />}
      <ConfigContext.Provider value={appConfigQuery.data}>
        {hasLoaded && urlParams.screen === 'listing' && (
          <ListingScreen
            data={data}
            screen={urlParams.thisScreenNum}
            totalScreens={urlParams.totalScreens}
            loc={urlParams.location}
            screenType={urlParams.screen}
          />
        )}
        {hasLoaded && urlParams.screen === 'courtid' && data && (
          <CourtIdScreen
            data={data}
            location={urlParams?.location}
            config={appConfigQuery.data}
            params={urlParams}
          />
        )}
        {hasLoaded && urlParams.screen === 'lobby' && data && (
          <LobbyScreen
            data={data}
            screen={urlParams.thisScreenNum}
            totalScreens={urlParams.totalScreens}
          />
        )}
        {!hasLoaded && <p>Loading...</p>}
      </ConfigContext.Provider>
    </div>
  )
}

export default App
