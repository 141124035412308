import { createContext } from 'react'

const defaultData = {
  majorColor: '',
  minorColor: '',
  textColor: '',
  subtextColor: '',
  jurisdictionHeaderColor: '',
  borderColor: '',
  progressBarColor: '',
  progressBarTextColor: '',
  progressBarSpeed: '',
}

export const ConfigContext = createContext(defaultData)
