import React, { useState, useEffect, useContext } from 'react'
import { CourtIdHeader } from './CourtIdHeader'
import {
  StyledDirections,
  StyledCourtIdScreen,
  StyledJurisdictionHeader,
  StyledProgressWrapper,
  StyledNextJurisdictionHeader,
} from './CourtIdScreen.styles'
import { Judges } from './Judges'
import { CourtIdHearingList } from './CourtIdHearingList'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { Footer } from './Footer/Footer'
import { CourtroomClosed } from './CourtroomClosed'
import { Clerk } from './Clerk'
import { FV } from './FV'
import { StyledFVSpace } from './FV.styles'
import { ConfigContext } from '../../context/appConfig.jsx'

interface Props {
  data: HearingType[]
  location?: string
  config?: any
  params: UrlParamsType
}

interface Days {
  [key: string]: string
}
const courts = {
  mcv: `Magistrates' Court`,
  cc: 'County Court',
  sc: 'Supreme Court',
  vcat: 'VCAT',
  chcv: "Children's Court",
  iv: 'Intervention',
}

const days: Days = {
  '0': 'sunday',
  '1': 'monday',
  '2': 'tuesday',
  '3': 'wednesday',
  '4': 'thursday',
  '5': 'friday',
  '6': 'saturday',
}

const pruneData = (
  hearings: HearingType[],
  jurisdiction?: ValidJurisdictionTypes
) => hearings.filter((hearing) => hearing.jurisdiction === jurisdiction)

const calculateChcvRoomtype = (
  data: HearingType[],
  jurisdiction?: ValidJurisdictionTypes
) => {
  return jurisdiction === 'chcv' && data && data[0]
    ? data[0]?.hearingType?.toLowerCase()
    : 'hearing'
}

type Rows = 5 | 10 | 20 | 8
const calculateRows = (
  data: HearingType[],
  jurisdiction?: ValidJurisdictionTypes
) => {
  let rows: Rows = 5
  const calculatedData = pruneData(data, jurisdiction)
  const calculatedChcvRoomType = calculateChcvRoomtype(data, jurisdiction)

  if (jurisdiction === 'mcv' || jurisdiction === 'vcat') {
    if (calculatedData.length > 10) {
      rows = 20
    } else {
      rows = 10
    }
  } else if (jurisdiction === 'chcv') {
    if (calculatedChcvRoomType?.match(/conciliation conference/i)) {
      rows = 10
    } else {
      rows = 8
    }
  }

  return rows
}

const calculatePageCeiling = (
  data: HearingType[],
  jurisdiction?: ValidJurisdictionTypes
) => {
  const rows = calculateRows(data, jurisdiction)
  const calculatedData = pruneData(data, jurisdiction)

  // Return page ceiling = 1 for jurisdiction = iv
  if (jurisdiction === 'iv') {
    return 1
  }
  return Math.ceil(calculatedData.length / rows)
}

const calculateJurisdictionIndex = (
  data: HearingType[],
  jurisdictions: ValidJurisdictionTypes[],
  pageNumber: number
) => {
  let pageCount = 0

  for (let i = 0; i < jurisdictions.length; i += 1) {
    const jurisdiction = jurisdictions[i]

    pageCount += calculatePageCeiling(data, jurisdiction)
    if (pageCount >= pageNumber) return i
  }

  return 0
}

export const CourtIdScreen: React.FC<Props> = ({
  data,
  location = undefined,
  config = undefined,
  params,
}) => {
  const [calculatedData, setCalculatedData] = useState<HearingType[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const appConfig = useContext(ConfigContext)
  const checkFV = () => {
    const currDay = new Date().getDay()
    const IVHearingsDay = config?.interventionHearings?.days?.find(
      (element: string) => {
        return element.toLowerCase() === days[currDay].toLowerCase()
      }
    )
    if (
      IVHearingsDay &&
      config?.interventionHearings?.courtRoom?.includes(params.courtRoom)
    ) {
      return true
    }
    return false
  }

  // Get list of all jurisdictions using this court room
  let jurisdictions = data.map((hearing) => hearing.jurisdiction)

  // Append IV in jurisdictions
  if (
    config?.interventionHearings?.courtRoom?.includes(params.courtRoom) &&
    checkFV() === true
  ) {
    jurisdictions.push('iv')
  }
  jurisdictions = Array.from(new Set(jurisdictions))

  const calculatedJurisdictionIndex = calculateJurisdictionIndex(
    data,
    jurisdictions,
    currentPage
  )

  const calculatedJurisdiction =
    jurisdictions.length > 0
      ? jurisdictions[calculatedJurisdictionIndex]
      : undefined

  const nextJurisdiction =
    jurisdictions.length > 0
      ? jurisdictions[(calculatedJurisdictionIndex + 1) % jurisdictions.length]
      : undefined

  useEffect(() => {
    const prunedData = pruneData(data, calculatedJurisdiction)

    setCalculatedData(prunedData)
  }, [data, calculatedJurisdiction])

  const currentJurisdictionPageCount = calculatePageCeiling(
    data,
    calculatedJurisdiction
  )

  const totalPageCount = jurisdictions.reduce<number>(
    (pages, currentJurisdicion) => {
      const pageCeiling = calculatePageCeiling(data, currentJurisdicion)

      return pages + pageCeiling
    },
    0
  )

  const rows = calculateRows(data, calculatedJurisdiction)
  const calculatedChcvRoomType = calculateChcvRoomtype(
    data,
    calculatedJurisdiction
  )
  const pageCeiling = calculatePageCeiling(data, calculatedJurisdiction)

  const previousJurisdictions = jurisdictions.slice(
    0,
    calculateJurisdictionIndex(data, jurisdictions, currentPage)
  )
  const previousJurisdictionsPageCount = previousJurisdictions.reduce(
    (pageCount, currentJurisdiction) =>
      pageCount + calculatePageCeiling(data, currentJurisdiction),
    0
  )

  const currentJurisdictionPage = currentPage - previousJurisdictionsPageCount

  const refreshEvent = (page: number) => {
    setCurrentPage(page)
  }

  const showProgressFrame = !(jurisdictions.length === 1 && pageCeiling === 1)

  const isSlim =
    calculatedJurisdiction === 'mcv' ||
    calculatedJurisdiction === 'vcat' ||
    calculatedJurisdiction === 'chcv'

  return (
    <>
      {jurisdictions.length === 0 && (
        <CourtroomClosed location={location} config={config} />
      )}
      {calculatedJurisdiction !== undefined && (
        <StyledCourtIdScreen>
          <CourtIdHeader slim={isSlim} />
          <StyledJurisdictionHeader id="jurisdictionHeader" slim={isSlim}>
            <div data-testid="currentJurisdiction" id="currentJurisdiction">
              {checkFV() && calculatedJurisdiction === 'iv'
                ? config?.interventionHearings?.jurisdiction
                : courts[calculatedJurisdiction]}
            </div>
            {jurisdictions.length > 1 && nextJurisdiction !== undefined ? (
              <StyledNextJurisdictionHeader
                id="nextJurisdictionHeader"
                data-testid="nextJurisdictionHeader"
              >
                <div>Next list: </div>
                <div id="nextJurisdictionHeader">
                  {checkFV() && nextJurisdiction === 'iv'
                    ? config?.interventionHearings?.jurisdiction
                    : courts[nextJurisdiction]}
                </div>
              </StyledNextJurisdictionHeader>
            ) : (
              ''
            )}
          </StyledJurisdictionHeader>
          <Judges
            data={calculatedData}
            jurisdiction={calculatedJurisdiction}
            fixture={calculatedChcvRoomType}
            slim={isSlim}
            iVHearings={checkFV}
            config={config}
          />
          {calculatedJurisdiction === 'chcv' &&
            !calculatedChcvRoomType?.match(/conciliation conference/i) && (
              <Clerk
                data={calculatedData}
                jurisdiction={calculatedJurisdiction}
                slim={isSlim}
              />
            )}
          <StyledDirections slim={isSlim} id="roomGuidelines">
            {checkFV() && (calculatedJurisdiction === 'iv') === true
              ? config?.interventionHearings?.directions
              : 'Please enter quietly'}
          </StyledDirections>
          <>
            {checkFV() && calculatedJurisdiction === 'iv' ? (
              <StyledFVSpace accomodateProgressBar>
                {config?.interventionHearings?.content}
              </StyledFVSpace>
            ) : (
              <CourtIdHearingList
                data={calculatedData}
                rowsPerPage={rows}
                page={currentJurisdictionPage}
                jurisdiction={calculatedJurisdiction}
              />
            )}

            {showProgressFrame && (
              <StyledProgressWrapper slim={isSlim} id="progressBar">
                {pageCeiling && (
                  <ProgressBar
                    height="1.4vh"
                    onRefresh={refreshEvent}
                    totalPages={totalPageCount}
                    singleScreenDuration={
                      process.env.NODE_ENV !== 'test'
                        ? Number(appConfig.progressBarSpeed)
                        : 24
                    }
                    backgroundColor="#f4f2f2"
                    rightGradient="#515557"
                    progTextColor="#2f3030"
                  />
                )}
              </StyledProgressWrapper>
            )}
          </>
          <Footer slim={isSlim} progressBar={showProgressFrame} />
        </StyledCourtIdScreen>
      )}
    </>
  )
}
