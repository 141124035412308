import styled from 'styled-components'

interface StyledProps {
  state: 'entering' | 'entered' | 'exiting' | 'exited' | 'unmounted'
  letter: boolean
  appConfig?: any
  loc?: string
}

interface TimeColProps {
  jur: boolean
}

export const StyledListingHearingSlot = styled.li<StyledProps>`
  box-sizing: border-box;
  color: ${(props) => props.appConfig.textColor};
  background-color: ${(props) => props.appConfig.minorColor};
  border-bottom: 0.2vh solid ${(props) => props.appConfig.borderColor};
  height: 9.3vh;
  padding-top: ${(props) => (props.loc === 'wcjc' ? '0.25vh' : '0.75vh')};
  padding-left: ${(props: StyledProps) => (props.letter ? 0 : '12.8vw')};
  display: grid;
  grid-template-columns: ${(props: StyledProps) =>
    props.letter ? '0.4fr 1.75fr 0.75fr 0.4fr' : '1.75fr 0.75fr 0.4fr'};
  gap: 1.1vw;

  opacity: ${(props: StyledProps) =>
    props.state === 'entering' || props.state === 'entered' ? 1 : 0};
  transition: opacity 300ms ease-in-out;

  p {
    font-size: 1.5vh;
    font-weight: bold;
    margin: 0;
  }
  span {
    font-size: 1.3vh;
    font-weight: normal;
    margin: 0;
    color: ${(props) => props.appConfig.subtextColor};
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
`

export const StyledHearingInfo = styled.div<StyledProps>`
  padding-right: 3.1vw;

  p {
    font-weight: bold;
    display: -webkit-box;
    font-size: 1.5vh;
    font-weight: bold;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    line-height: 1.3;

    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
  span {
    font-size: 1.3vh;
    color: ${(props: StyledProps) => props.appConfig.subtextColor};
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    line-height: 1.3;
  }
`

export const StyledListingOrder = styled.h3`
  font-size: 3.57vh;
  margin: 0 auto;
  padding-left: 2.5vw;
  /* Keep below until  tested on 65" */
  /* Alignment on broswer margin-top: -0.2rem; */
  margin-top: -0.8rem;
`

export const StyledHearingRoom = styled.div`
  padding: 0 1vw;
  box-sizing: border-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  p {
    line-clamp: 4;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
`

export const StyledHearingTime = styled.div<TimeColProps>`
  box-sizing: border-box;
  p {
    font-size: 1.5vh;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    white-space: nowrap;
  }

  span {
    font-size: 1.3vh;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: ${(props) => (props.jur ? '90%' : '50%')};
  }
`
