// import { createTestHearingsOfLength } from '../util'

const jurToRoomMappings: any = {
  mcv: {
    courtRooms: {
      'g.1': 6,
      2: 4,
    },
    level: 'Level 4',
  },
  sc: {
    courtRooms: {
      3: 10,
    },
    level: 'Level 7',
  },
  cc: {
    courtRooms: {
      4: 2,
    },
    level: 'Level 7',
  },
  vcat: {
    courtRooms: {
      5: 10,
    },
    level: 'Level 7',
  },
  chcv: {
    courtRooms: {
      'g.1': 5,
      'g.2': 6,
    },
    level: 'Ground 2',
  },
}

export const createHearings = () => {
  const hearings: HearingType[] = []
  const jurisdictions = Object.keys(jurToRoomMappings)
  jurisdictions.forEach((currJur) => {
    const currentJurisdictionObj = jurToRoomMappings[currJur]
    const courtRooms = Object.keys(currentJurisdictionObj.courtRooms)
    courtRooms.forEach((room) => {
      const roomCount = currentJurisdictionObj.courtRooms[room]

      for (let i = 0; i < roomCount; i += 1) {
        const hearingObj: HearingType = {
          hearingId: `hdgcwu36834${i}`,
          matterDescription: `Record ${i + 1} of ${roomCount} - ${currJur}`,
          jurisdiction: currJur as ValidJurisdictionTypes,
          caseNumber: 'H394/2019',
          caseType: 'Directions Hearing',
          hearingStatus: 'Hearing Scheduled',
          hearingStatusText: 'Please checkin ',
          hearingDateTime: new Date('2022-06-21T09:00:00'),
          courtRoom: room,
          level: currentJurisdictionObj.level,
          judgeName: 'Vice President Judge Marks',
          hearingType: 'VALID TYPE',
          location: 'Bendigo',
        }
        hearings.push(hearingObj)
      }
    })
  })

  return { hearings }
}

export const mockHearings = createHearings()

// export const successData = {
//   hearings: createTestHearingsOfLength(10, {
//     matterDescription: [
//       'Marc Lambert v. Danielle Spence',
//       'Mai Graves v. Bettye Shea',
//       'Lenny Dixon v. Garry Sutton',
//       'Ambrose Lynch v. Tyron Hebert',
//       'Arron Chase v. Merle Fritz',
//       'Judith Rosales v. Dillon Bowen',
//       'Eliza Prince v. Jame Camacho',
//       'Terence Mckay v. Rashad Galloway',
//       'Tonya Montoya v. Gene Contreras',
//       'Mikel Carney v. Damon Cunningham',
//     ],
//     jurisdiction: ['SC', 'mcv', 'cc', 'vcat'],
//     caseNumber: 'S ECI 2024 00197',
//     caseType: [
//       'Court of Appeal - Associate Judges',
//       'Court of Appeal - Civil Division',
//       'Court of Appeal - Criminal Division',
//       'Trial Division - Appeal from Magistrates Court',
//       'Trial Division - Associate Judges',
//       'Trial Division - Commercial Court Duty Judge',
//       'Trial Division - Commercial Court',
//       'Trial Division - Common Law Division',
//       'Trial Division - Costs Court',
//       'Trial Division - Criminal Division',
//       'Trial Division - Practice Court (Common Law)',
//       'Trial Division - Pre-Trial Conferences',
//       'Trial Division - Z Probate',
//     ],
//     hearingStatus: 'Hearing Scheduled',
//     hearingStatusText: [
//       'Application',
//       'Assess Damages',
//       'Application for Costs',
//       'Application (Virtual)',
//       'Case Management Conference',
//       'Application to Join Party/s',
//       'Application for Restrictive Covenant',
//       'Company Application not for Winding Up',
//       'Post Mediation Directions Hearing (Virtual)',
//       'Application to vacate the trial date (Virtual)',
//     ],
//     hearingDateTime: new Date('2022-07-02T10:00:00'),
//     // courtRoom: ['2', '3', '4', '5', '12', '10'],
//     courtRoom: ['g.1', '4a', '1', '2'],
//     level: 'Level 7',
//     judgeName: 'Associate Justice MCCARTHY',
//     hearingType: 'VALID TYPE',
//     jurToRoomMappings,
//   }),
// }

// export const successDataChCV = {
//   hearings: [
//     {
//       parties: null,
//       hearingId: 'be1fdb22-1815-4cba-8b39-6e833459a63a',
//       matterDescription: 'Criminal Mentions',
//       jurisdiction: 'ChCV',
//       caseNumber: 'CM',
//       caseType: 'Criminal Mentions',
//       hearingType: 'Heading',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '585ef6d7-0fa9-472e-95a3-9079aa106f5e',
//       matterDescription: 'Emergency Care Applications',
//       jurisdiction: 'ChCV',
//       caseNumber: 'ECA',
//       caseType: 'Emergency Care Applications',
//       hearingType: 'Heading',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '85708411-db89-4521-a51c-5db7fae00efa',
//       matterDescription: 'DFFH v Batman',
//       jurisdiction: 'ChCV',
//       caseNumber: '4985/62',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: 'Siobhan',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '0ac6b184-683d-4e99-9b61-35de3c160631',
//       matterDescription: 'DFFH v GB ',
//       jurisdiction: 'ChCV',
//       caseNumber: '219/16',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription:
//         'DFFH v Novak DFFH v Novak DFFH v Novak DFFH v Novak DFFH v Novak DFFH',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType:
//         'Conciliation Conference Conciliation Conference Conciliation Conference',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: 'c00fed81-c50a-478d-887b-cca8831345ad',
//       matterDescription: 'DFFH v Roger Federer',
//       jurisdiction: 'ChCV',
//       caseNumber: '4879/36',
//       caseType: 'Childrens',
//       hearingType: 'Readiness Hearing ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },

//     {
//       parties: null,
//       hearingId: '1ff35536-aa45-4bab-a41b-309aa91d6b6b',
//       matterDescription: 'Exit v Enter',
//       jurisdiction: 'ChCV',
//       caseNumber: ' 4398/34',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: 'c87d7281-be34-4e77-a319-0a0922921036',
//       matterDescription: 'Family Division Mentions ',
//       jurisdiction: 'ChCV',
//       caseNumber: 'FDM',
//       caseType: 'Family Division Mentions ',
//       hearingType: 'Heading',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: 'Ms McRae',
//       clerkName: 'Chelsea/Mark',
//     },
//     {
//       parties: null,
//       hearingId: '6a4f7835-f433-475a-ac2a-b35e619a3206',
//       matterDescription: '1 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: '4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '222f7835-f433-475a-ac2a-b35e619a3206',
//       matterDescription: '8 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: ' 4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '44447835-f433-475a-ac2a-b35e619a3206',
//       matterDescription: '10 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: ' 4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '44447835-f433-475a-ac2a-b35e619a32a1',
//       matterDescription: '20 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: ' 4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '44447835-f433-475a-ac2a-b35e619a32a2',
//       matterDescription: '30 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: ' 4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//   ],
// }

// export const frontendConfig = {
//   bendigo: {
//     courtroomClosedMsg: 'No hearings in this room today',
//     courtroomOpenMsg: 'Please enter quietly',
//     displayCross: 'True',
//     interventionHearings: {
//       courtRoom: ['3.4', 'g.1'],
//       days: ['monday', 'tuesday'],
//       jurisdiction: 'Intervention',
//       title: 'Intervention order hearings',
//       directions: 'Individual cases are not published',
//       content:
//         'Once you have checked in at the Balak Services Counter on Level 1, please take a seat and wait for your matter to be called.',
//     },
//     major_color: '#f7f7f7',
//     minor_color: '#f4f2f2',
//     text_color: '#000000',
//     subtext_color: '#626668',
//     jurisdiction_header_color: '#e4e2e2',
//     border_color: '#ffffff',
//     progress_bar_color: '#515557',
//     progress_bar_text_color: '#ffffff',
//     progress_bar_speed: '24',
//   },
//   shepparton: {
//     courtroomClosedMsg: 'See General Enquiries',
//     courtroomOpenMsg: 'Please enter quietly',
//     displayCross: 'False',
//     major_color: '#f7f7f7',
//     minor_color: '#f4f2f2',
//     text_color: '#000000',
//     subtext_color: '#626668',
//     jurisdiction_header_color: '#e4e2e2',
//     border_color: '#ffffff',
//     progress_bar_color: '#515557',
//     progress_bar_text_color: '#ffffff',
//     progress_bar_speed: '24',
//   },
// wcjc: {
//   courtroomClosedMsg: 'No hearings in this room today',
//   courtroomOpenMsg: 'Please enter quietly',
//   displayCross: 'True',
//   major_color: '#f7f7f7',
//   minor_color: '#f4f2f2',
//   text_color: '#000000',
//   subtext_color: '#626668',
//   jurisdiction_header_color: '#e4e2e2',
//   border_color: '#ffffff',
//   progress_bar_color: '#515557',
//   progress_bar_text_color: '#ffffff',
//   progress_bar_speed: '24',
// },
// }

// export const successData = {
//   hearings: [
//     'invalid hearing type - not an object',
//     {
//       parties: null,
//       hearingId: 'a30bf995-0670-43d8-b530-bad-jurisdiction',
//       matterDescription: 'Test Invalid Hearing with Bad Jurisdiction',
//       jurisdiction: 'invalid',
//       caseNumber: 'C6796/2021',
//       caseType: 'Civil Claims Hearing',
//       hearingType: 'Civil Claims List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:30:00',
//       applicants: 'Adrian Federenko',
//       respondents: 'Centre Com Sunshine Pty Ltd ACN:084165293',
//       location: 'Bendigo',
//       courtRoom: '4A',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Senior Member A. Vassie ',
//     },
//     {
//       parties: null,
//       hearingId: 'a30bf995-0670-43d8-b530-no-court-room',
//       matterDescription: 'Test Invalid Hearing with No Court Room',
//       jurisdiction: 'SC',
//       caseNumber: 'C6796/2021',
//       caseType: 'Civil Claims Hearing',
//       hearingType: 'Civil Claims List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to room',
//       hearingDateTime: '2022-06-21T09:30:00',
//       applicants: 'Adrian Federenko',
//       respondents: 'Centre Com Sunshine Pty Ltd ACN:084165293',
//       location: 'Bendigo',
//       lawyerRequired: false,
//       judgeName: 'Senior Member A. Vassie ',
//     },
//     {
//       parties: null,
//       hearingId: 'a30bf995-0670-43d8-b530-no-court-room',
//       matterDescription: 'Test Invalid Hearing with No Hearing Date/Time',
//       jurisdiction: 'SC',
//       caseNumber: 'C6796/2021',
//       caseType: 'Civil Claims Hearing',
//       hearingType: 'Civil Claims List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to room',
//       applicants: 'Adrian Federenko',
//       respondents: 'Centre Com Sunshine Pty Ltd ACN:084165293',
//       location: 'Bendigo',
//       courtRoom: '4A',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Senior Member A. Vassie ',
//     },
//     {
//       parties: null,
//       hearingId: 'a30bf995-0670-43d8-b530-1a42d4807755',
//       matterDescription:
//         'Adrian Federenko v Centre Com Sunshine Pty Ltd ACN:084165293',
//       jurisdiction: 'SC',
//       caseNumber: 'C6796/2021',
//       caseType: 'Civil Claims Hearing',
//       hearingType: 'Civil Claims List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to room',
//       hearingDateTime: '2022-06-21T09:30:00',
//       applicants: 'Adrian Federenko',
//       respondents: 'Centre Com Sunshine Pty Ltd ACN:084165293',
//       location: 'Bendigo',
//       courtRoom: '3C',
//       level: 'Level 3',
//       lawyerRequired: false,
//       judgeName: 'Senior Member A. Vassie ',
//     },
//     {
//       parties: null,
//       hearingId: '3b51430c-61a1-4cd0-8afd-7f1ed19565d9',
//       matterDescription:
//         'Avari Holdings Pty Ltd (ACN: 603 200 648) v Owners Corporation Plan No 1 429255Y',
//       jurisdiction: 'vcat',
//       caseNumber: 'OC619/2021',
//       caseType: 'Compulsory Conference',
//       hearingType: 'Planning and environment',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText:
//         'longer than BR Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:30:00',
//       applicants: 'Avari Holdings Pty Ltd (ACN: 603 200 648)',
//       respondents: 'Owners Corporation Plan No 1 429255Y',
//       location: 'Bendigo',
//       courtRoom: '4A',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Member Dr R. Leshinsky',
//     },
//     {
//       parties: null,
//       hearingId: '78440ae8-ae08-48f8-afc5-e802ec8930b1',
//       matterDescription:
//         'THIS IS A TEST ALL CAPS LONG DESCRIPTION Avivit Ellis v Goldsmith Lawyers',
//       jurisdiction: 'VCAT',
//       caseNumber: 'J43/2021',
//       caseType: 'Directions Hearing',
//       hearingType: 'Legal Practice List',
//       hearingStatus: 'Hearing Complete',
//       hearingStatusText: null,
//       hearingDateTime: '2022-06-21T16:30:00',
//       applicants: 'Avivit Ellis',
//       respondents: 'Goldsmith Lawyers',
//       location: 'Bendigo',
//       courtRoom: 'COURT ROOM 4B',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Member R. Tang',
//     },
//     {
//       parties: null,
//       hearingId: 'e35613bd-804d-47bb-a1a3-8627b0c4f6f8',
//       matterDescription: 'Brendan Sansonetti v Clint Taylor',
//       jurisdiction: 'VCAT',
//       caseNumber: 'C275/2022',
//       caseType: 'Fast Track Mediation and Hearing',
//       hearingType: 'Civil Claims List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:00:00',
//       applicants: 'Brendan Sansonetti',
//       respondents: 'Clint Taylor',
//       location: 'Bendigo',
//       courtRoom: '4B',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Mediator H. Singh',
//     },
//     {
//       parties: null,
//       hearingId: 'e35613bd-804d-47bb-a1a3-8627bsfsd3fd3',
//       matterDescription: 'Rafael Nadal vs Roger Federer',
//       jurisdiction: 'VCAT',
//       caseNumber: 'C275/2022',
//       caseType: 'Fast Track Mediation and Hearing',
//       hearingType: 'Civil Claims List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T10:00:00',
//       applicants: 'Rafael Nadal',
//       respondents: 'Roger Federer',
//       location: 'Bendigo',
//       courtRoom: '4B',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Mediator H. Singh',
//     },
//     {
//       parties: null,
//       hearingId: 'e35613bd-804d-47bb-a1a3-8627ghrt56f2',
//       matterDescription: 'Geralt Rivia vs Emhyr var Emreis',
//       jurisdiction: 'VCAT',
//       caseNumber: 'C275/2022',
//       caseType: 'Ciri custody hearing',
//       hearingType: 'Civil Claims List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T11:00:00',
//       applicants: 'Geralt Rivia',
//       respondents: 'Emhyr var Emreis',
//       location: 'Bendigo',
//       courtRoom: '4B',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Lorem Ipsum',
//     },
//     {
//       parties: null,
//       hearingId: '5d67b3bd-54cb-4b38-87fb-5c829edeeaf8',
//       matterDescription: 'David Cheasley',
//       jurisdiction: 'VCAT',
//       caseNumber: 'BP726/2018',
//       caseType: 'Mediation',
//       hearingType: 'Building and Property List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:30:00',
//       applicants: 'Hollie Winfield',
//       respondents:
//         'Zacon Constructions Pty Ltd (ACN: 163 912 972)","location":"Bendigo',
//       courtRoom: '4A',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Member to be allocated/assigned',
//     },
//     {
//       parties: null,
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11e7b0d',
//       matterDescription: `Anthia Arhontissas v Star Track Express Pty Ltd, Paul Mckenna Anthia
//         Arhontissas v Star Track Express Pty Ltd, Paul Mckenna Anthia Arhontissas v Star Track
//         Express Pty Ltd, Paul Mckenna Anthia Arhontissas v Star Track Express Pty Ltd, Paul
//         Mckenna Anthia Arhontissas v Star Track Express Pty Ltd, Paul Mckenna Anthia Arhontissas
//         v Star Track Express Pty Ltd, Paul Mckenna`,
//       jurisdiction: 'VCAT',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingType: 'Human Rights List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:00:00',
//       applicants: 'Anthia Arhontissas',
//       respondents: 'Star Track Express Pty Ltd, Paul Mckenna',
//       location: 'Bendigo',
//       courtRoom: ' ROOM NUMBER 4A',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Vice President Judge Marks',
//     },
//     {
//       parties: null,
//       hearingId: '1e97b456-83b1-43e9-8c7e-bd9560c3fcae',
//       matterDescription: 'Goldsmith Lawyers v Avivit Ellis',
//       jurisdiction: 'VCAT',
//       caseNumber: 'J75/2021',
//       caseType: 'Directions Hearing',
//       hearingType: 'Legal Practice List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:30:00',
//       applicants: 'Goldsmith Lawyers',
//       respondents: 'Avivit Ellis',
//       location: 'Bendigo',
//       courtRoom: '4A',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Member R. Tang',
//     },
//     {
//       parties: null,
//       hearingId: '93ce41bd-d0e9-4b4c-9497-11a22d51ec50',
//       matterDescription: `James Milner, Graham Stephens, The Public Advocate, Allan Milner, Denise
//         Somerville, Paul Milner, Julie Milner, Susan Monahan, Jane Milner, Garden & Green Lawyers,
//         State Trustees Limited James Milner, Graham Stephens, The Public Advocate, Allan Milner,
//         Denise Somerville, Paul Milner, Julie Milner, Susan Monahan, Jane Milner, Garden & Green
//         Lawyers, State Trustees Limited`,
//       jurisdiction: 'VCAT',
//       caseNumber: 'G89763/04',
//       caseType: 'Standard',
//       hearingType: 'G',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:30:00',
//       applicants: 'Guardianship List',
//       respondents: null,
//       location: 'Bendigo',
//       courtRoom: '4A',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Deputy President Nihill',
//     },
//     {
//       parties: null,
//       hearingId: '6c30f8c6-cab7-4dc4-b831-174c2d91bfd7',
//       matterDescription:
//         'Justin Balasa, Karen Clements v Waltara Homes Pty Ltd (ACN 129 272 126)',
//       jurisdiction: 'VCAT',
//       caseNumber: 'BP675/2022',
//       caseType: 'Directions Hearing',
//       hearingType: 'Building and Property List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:30:00',
//       applicants: 'Justin Balasa, Karen Clements',
//       respondents: 'Waltara Homes Pty Ltd (ACN 129 272 126)',
//       location: 'Bendigo',
//       courtRoom: '4A',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Senior Member M. Farrelly, Judge Sean, Member Skye',
//     },
//     {
//       parties: null,
//       hearingId: '1d1a14ba-f4e5-4e19-9e16-ac8b77ced489',
//       matterDescription: 'Katrina Chatfield, Charlie Rogers v Sandie Earl',
//       jurisdiction: 'vcat',
//       caseNumber: 'C186/2022',
//       caseType: 'Fast Track Mediation and Hearing',
//       hearingType: 'Civil Claims List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:00:00',
//       applicants: 'Katrina Chatfield, Charlie Rogers',
//       respondents: 'Sandie Earl',
//       location: 'Bendigo',
//       courtRoom: '4A',
//       level: 'Level 4',
//       lawyerRequired: false,
//       judgeName: 'Mediator L. Stewart',
//     },
//     {
//       parties: null,
//       hearingId: 'df783368-f416-4549-9686-1926fbc984b9',
//       matterDescription: `Laxton Construction Pty Ltd (ACN: 158 036 705) t/as Urban Construction
//         v EcoFit Electrical Pty Ltd (ACN: 618 931 427)`,
//       jurisdiction: 'VCAT',
//       caseNumber: 'BP1248/2021',
//       caseType: 'Mediation',
//       hearingType: 'Building and Property List',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at VCAT services on level 3',
//       hearingDateTime: '2022-06-21T09:30:00',
//       applicants:
//         'Laxton Construction Pty Ltd (ACN: 158 036 705) t/as Urban Construction',
//       respondents: 'EcoFit Electrical Pty Ltd (ACN: 618 931 427)',
//       location: 'Bendigo',
//       courtRoom: '2A',
//       level: 'Level 2',
//       lawyerRequired: false,
//       judgeName: 'Member to be allocated/assigned',
//     },
//     {
//       parties: null,
//       hearingId: 'cee64701-7eff-44af-aeb5-300ad413247c',
//       matterDescription: 'Apolonija Mieldazys',
//       jurisdiction: 'VCAT',
//       applicants: 'Guardianship List',
//       caseNumber: 'G74251/03',
//       caseType: 'G',
//       courtRoom: 'Room A',
//       level: 'Level 4',
//       hearingDateTime: '2022-06-28T11:30:00',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please check in at the VCAT counter on level 3',
//       hearingType: 'Standard',
//       judgeName: 'A. Smith',
//       lawyerRequired: false,
//       location: 'Bendigo',
//       respondents: null,
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11e7ONE',
//       matterDescription: 'Record One of SIX',
//       jurisdiction: 'cc',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to room',
//       hearingDateTime: new Date('2022-06-21T09:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11e7TWO',
//       matterDescription: 'Record Two of SIX',
//       jurisdiction: 'cc',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to room',
//       hearingDateTime: new Date('2022-06-21T09:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11e7THREE',
//       matterDescription: 'Record Three of SIX',
//       jurisdiction: 'cc',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to room',
//       hearingDateTime: new Date('2022-06-21T09:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11e7FOUR',
//       matterDescription: 'Record Four of SIX',
//       jurisdiction: 'cc',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-06-21T09:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11e7FIVE',
//       matterDescription: 'Record Five of SIX',
//       jurisdiction: 'cc',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-06-21T09:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11e7SIX',
//       matterDescription: 'Record Six of SIX',
//       jurisdiction: 'cc',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-06-21T09:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-ONE',
//       matterDescription: 'VCAT Screenigination ONE of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T09:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-TWO',
//       matterDescription: 'VCAT Screenigination TWO of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T09:20:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-THREE',
//       matterDescription: 'VCAT Screenigination THREE of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T09:40:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-FOUR',
//       matterDescription: 'VCAT Screenigination FOUR of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T10:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-FIVE',
//       matterDescription: 'VCAT Screenigination FIVE of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T09:50:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-SIX',
//       matterDescription: 'VCAT Screenigination SIX of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T10:20:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-SEVEN',
//       matterDescription: 'VCAT Screenigination SEVEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T10:40:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-EIGHT',
//       matterDescription: 'VCAT Screenigination EIGHT of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T11:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-NINE',
//       matterDescription: 'VCAT Screenigination NINE of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T09:10:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-TEN',
//       matterDescription: 'VCAT Screenigination TEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T11:20:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-ELEVEN',
//       matterDescription: 'VCAT Screenigination ELEVEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T11:40:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-TWELVE',
//       matterDescription: 'VCAT Screenigination TWELVE of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T12:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-THIRTEEN',
//       matterDescription: 'VCAT Screenigination THIRTEEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T12:10:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-FOURTEEN',
//       matterDescription: 'VCAT Screenigination FOURTEEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T12:20:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-FIFTEEN',
//       matterDescription: 'VCAT Screenigination FIFTEEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T12:30:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-SIXTEEN',
//       matterDescription: 'VCAT Screenigination SIXTEEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T12:40:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-SEVENTEEN',
//       matterDescription: 'VCAT Screenigination SEVENTEEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T12:50:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-EIGHTTEEN',
//       matterDescription: 'VCAT Screenigination EIGHTTEEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T13:00:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-NINETEEN',
//       matterDescription: 'VCAT Screenigination NINETEEN of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T13:10:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-TWENTY',
//       matterDescription: 'VCAT Screenigination TWENTY of TWENTY',
//       jurisdiction: 'vcat',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please checkin ',
//       hearingDateTime: new Date('2022-07-02T13:20:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-TWENTYONE',
//       matterDescription: 'Supreme Court A-Z One of Two',
//       jurisdiction: 'SC',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to room',
//       hearingDateTime: new Date('2022-07-02T13:20:00'),
//       courtRoom: '4A',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-TWENTYTWO',
//       matterDescription: 'MCV A-Z Two of Two',
//       jurisdiction: 'MCV',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to General Enquiries on level 1',
//       hearingDateTime: new Date('2022-07-02T13:20:00'),
//       courtRoom: 'g.1',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-TWENTYTWO',
//       matterDescription: '1 MCV A-Z ',
//       jurisdiction: 'MCV',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to General Enquiries on level 1',
//       hearingDateTime: new Date('2022-07-02T13:20:00'),
//       courtRoom: '88',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-TWENTYTWO',
//       matterDescription: '12 MCV A-Z',
//       jurisdiction: 'MCV',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to General Enquiries on level 1',
//       hearingDateTime: new Date('2022-07-02T13:20:00'),
//       courtRoom: '88',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       hearingId: 'a32524c9-de61-4141-95a1-eb10d11eh-TWENTYTWO',
//       matterDescription: '2 MCV A-Z',
//       jurisdiction: 'MCV',
//       caseNumber: 'H394/2019',
//       caseType: 'Directions Hearing',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: 'Please go to General Enquiries on level 1',
//       hearingDateTime: new Date('2022-07-02T13:20:00'),
//       courtRoom: '88',
//       level: 'Level 4',
//       judgeName: 'Vice President Judge Marks',
//       hearingType: 'VALID TYPE',
//     },
//     {
//       parties: null,
//       hearingId: 'be1fdb22-1815-4cba-8b39-6e833459a63a',
//       matterDescription: 'Criminal Mentions',
//       jurisdiction: 'ChCV',
//       caseNumber: 'CM',
//       caseType: 'Criminal Mentions',
//       hearingType: 'Heading',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T10:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '585ef6d7-0fa9-472e-95a3-9079aa106f5e',
//       matterDescription: 'Emergency Care Applications',
//       jurisdiction: 'ChCV',
//       caseNumber: 'ECA',
//       caseType: 'Emergency Care Applications',
//       hearingType: 'Heading',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '85708411-db89-4521-a51c-5db7fae00efa',
//       matterDescription: 'DFFH v Batman',
//       jurisdiction: 'ChCV',
//       caseNumber: '4985/62',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: 'Siobhan',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '0ac6b184-683d-4e99-9b61-35de3c160631',
//       matterDescription: 'DFFH v GB ',
//       jurisdiction: 'ChCV',
//       caseNumber: '219/16',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription:
//         'DFFH v Novak DFFH v Novak DFFH v Novak DFFH v Novak DFFH v Novak DFFH',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType:
//         'Conciliation Conference Conciliation Conference Conciliation Conference',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: 'c00fed81-c50a-478d-887b-cca8831345ad',
//       matterDescription: 'DFFH v Roger Federer',
//       jurisdiction: 'ChCV',
//       caseNumber: '4879/36',
//       caseType: 'Childrens',
//       hearingType: 'Readiness Hearing ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     // {
//     //   parties: null,
//     //   hearingId: '1ff35536-aa45-4bab-a41b-309aa91d6b6b',
//     //   matterDescription: 'Exit v Enter',
//     //   jurisdiction: 'ChCV',
//     //   caseNumber: ' 4398/34',
//     //   caseType: 'Childrens',
//     //   hearingType: 'Part Heard Sentence ',
//     //   hearingStatus: 'Hearing Scheduled',
//     //   hearingStatusText: null,
//     //   hearingDateTime: '2022-09-16T09:00:00',
//     //   applicants: '',
//     //   respondents: '',
//     //   location: 'Bendigo',
//     //   courtRoom: 'G.1',
//     //   level: 'Ground 2',
//     //   lawyerRequired: false,
//     //   judgeName: '',
//     //   clerkName: '',
//     // },
//     // {
//     //   parties: null,
//     //   hearingId: 'c87d7281-be34-4e77-a319-0a0922921036',
//     //   matterDescription: 'Family Division Mentions ',
//     //   jurisdiction: 'ChCV',
//     //   caseNumber: 'FDM',
//     //   caseType: 'Family Division Mentions ',
//     //   hearingType: 'Heading',
//     //   hearingStatus: 'Hearing Scheduled',
//     //   hearingStatusText: null,
//     //   hearingDateTime: '2022-09-16T09:00:00',
//     //   applicants: '',
//     //   respondents: '',
//     //   location: 'Bendigo',
//     //   courtRoom: 'G.1',
//     //   level: 'Ground 2',
//     //   lawyerRequired: false,
//     //   judgeName: 'Ms McRae',
//     //   clerkName: 'Chelsea/Mark',
//     // },
//     // {
//     //   parties: null,
//     //   hearingId: '6a4f7835-f433-475a-ac2a-b35e619a3206',
//     //   matterDescription: 'matterDescription',
//     //   jurisdiction: 'chcv',
//     //   caseNumber: '4398/022',
//     //   caseType: 'Childrens',
//     //   hearingType: 'Part Heard Sentence ',
//     //   hearingStatus: 'Hearing Scheduled',
//     //   hearingStatusText: null,
//     //   hearingDateTime: '2022-09-16T09:00:00',
//     //   applicants: '',
//     //   respondents: '',
//     //   location: 'Bendigo',
//     //   courtRoom: 'G.1',
//     //   level: 'Ground 2',
//     //   lawyerRequired: false,
//     //   judgeName: '',
//     //   clerkName: '',
//     // },
//     // {
//     //   parties: null,
//     //   hearingId: '222f7835-f433-475a-ac2a-b35e619a3206',
//     //   matterDescription: 'Moh v VW',
//     //   jurisdiction: 'chcv',
//     //   caseNumber: ' 4398/022',
//     //   caseType: 'Childrens',
//     //   hearingType: 'Part Heard Sentence ',
//     //   hearingStatus: 'Hearing Scheduled',
//     //   hearingStatusText: null,
//     //   hearingDateTime: '2022-09-16T09:00:00',
//     //   applicants: '',
//     //   respondents: '',
//     //   location: 'Bendigo',
//     //   courtRoom: 'G.1',
//     //   level: 'Ground 2',
//     //   lawyerRequired: false,
//     //   judgeName: '',
//     //   clerkName: '',
//     // },
//     // {
//     //   parties: null,
//     //   hearingId: '44447835-f433-475a-ac2a-b35e619a3206',
//     //   matterDescription: 'Moh v VW',
//     //   jurisdiction: 'chcv',
//     //   caseNumber: ' 4398/022',
//     //   caseType: 'Childrens',
//     //   hearingType: 'Part Heard Sentence ',
//     //   hearingStatus: 'Hearing Scheduled',
//     //   hearingStatusText: null,
//     //   hearingDateTime: '2022-09-16T09:00:00',
//     //   applicants: '',
//     //   respondents: '',
//     //   location: 'Bendigo',
//     //   courtRoom: 'G.1',
//     //   level: 'Ground 2',
//     //   lawyerRequired: false,
//     //   judgeName: '',
//     //   clerkName: '',
//     // },
//   ],
// }

// export const successDataChCV = {
//   hearings: [
//     {
//       parties: null,
//       hearingId: 'be1fdb22-1815-4cba-8b39-6e833459a63a',
//       matterDescription: 'Criminal Mentions',
//       jurisdiction: 'ChCV',
//       caseNumber: 'CM',
//       caseType: 'Criminal Mentions',
//       hearingType: 'Heading',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '585ef6d7-0fa9-472e-95a3-9079aa106f5e',
//       matterDescription: 'Emergency Care Applications',
//       jurisdiction: 'ChCV',
//       caseNumber: 'ECA',
//       caseType: 'Emergency Care Applications',
//       hearingType: 'Heading',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '85708411-db89-4521-a51c-5db7fae00efa',
//       matterDescription: 'DFFH v Batman',
//       jurisdiction: 'ChCV',
//       caseNumber: '4985/62',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: 'Siobhan',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '0ac6b184-683d-4e99-9b61-35de3c160631',
//       matterDescription: 'DFFH v GB ',
//       jurisdiction: 'ChCV',
//       caseNumber: '219/16',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription:
//         'DFFH v Novak DFFH v Novak DFFH v Novak DFFH v Novak DFFH v Novak DFFH',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType:
//         'Conciliation Conference Conciliation Conference Conciliation Conference',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '3df62929-b126-4ed5-8f89-f606731139ad',
//       matterDescription: 'DFFH v Novak',
//       jurisdiction: 'ChCV',
//       caseNumber: '337/909',
//       caseType: 'Childrens',
//       hearingType: 'Conciliation Conference ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: 'c00fed81-c50a-478d-887b-cca8831345ad',
//       matterDescription: 'DFFH v Roger Federer',
//       jurisdiction: 'ChCV',
//       caseNumber: '4879/36',
//       caseType: 'Childrens',
//       hearingType: 'Readiness Hearing ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },

//     {
//       parties: null,
//       hearingId: '1ff35536-aa45-4bab-a41b-309aa91d6b6b',
//       matterDescription: 'Exit v Enter',
//       jurisdiction: 'ChCV',
//       caseNumber: ' 4398/34',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: 'c87d7281-be34-4e77-a319-0a0922921036',
//       matterDescription: 'Family Division Mentions ',
//       jurisdiction: 'ChCV',
//       caseNumber: 'FDM',
//       caseType: 'Family Division Mentions ',
//       hearingType: 'Heading',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: 'Ms McRae',
//       clerkName: 'Chelsea/Mark',
//     },
//     {
//       parties: null,
//       hearingId: '6a4f7835-f433-475a-ac2a-b35e619a3206',
//       matterDescription: '1 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: '4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '222f7835-f433-475a-ac2a-b35e619a3206',
//       matterDescription: '8 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: ' 4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '44447835-f433-475a-ac2a-b35e619a3206',
//       matterDescription: '10 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: ' 4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '44447835-f433-475a-ac2a-b35e619a32a1',
//       matterDescription: '20 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: ' 4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.1',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//     {
//       parties: null,
//       hearingId: '44447835-f433-475a-ac2a-b35e619a32a2',
//       matterDescription: '30 MCV - multi jurisdiction',
//       jurisdiction: 'mcv',
//       caseNumber: ' 4398/022',
//       caseType: 'Childrens',
//       hearingType: 'Part Heard Sentence ',
//       hearingStatus: 'Hearing Scheduled',
//       hearingStatusText: null,
//       hearingDateTime: '2022-09-16T09:00:00',
//       applicants: '',
//       respondents: '',
//       location: 'Bendigo',
//       courtRoom: 'G.2',
//       level: 'Ground 2',
//       lawyerRequired: false,
//       judgeName: '',
//       clerkName: '',
//     },
//   ],
// }

export const frontendConfig = {
  bendigo: {
    digitalDisplayContent: {
      courtroomClosedMsg: null,
      courtroomOpenMsg: null,
      displayCross: null,
      majorColor: '#375582',
      minorColor: '#193769',
      textColor: '#ffffff',
      titleColor: '#ffffff',
      subtextColor: '#ffffff',
      jurisdictionHeaderColor: '#375582',
      borderColor: '#375582',
      progressBarColor: '#ffffff',
      progressBarTextColor: '#000000',
      progressBarSpeed: null,
    },
  },
  wyndham: {
    digitalDisplayContent: null,
  },
  default: {
    interventionHearings: null,
    hearingStatusText: null,
    digitalDisplayContent: {
      courtroomClosedMsg: 'No hearings in this room today',
      courtroomOpenMsg: 'Please enter quietly',
      displayCross: 'True',
      majorColor: '#f7f7f7',
      minorColor: '#f4f2f2',
      textColor: '#000000',
      titleColor: '#000000',
      subtextColor: '#626668',
      jurisdictionHeaderColor: '#e4e2e2',
      borderColor: '#ffffff',
      progressBarColor: '#515557',
      progressBarTextColor: '#ffffff',
      progressBarSpeed: '24',
    },
  },
}
