import styled from 'styled-components'

interface Props {
  appConfig?: any
}

export const StyledListingHearingSlot = styled.li<Props>`
  box-sizing: border-box;
  color: ${(props) => props.appConfig.textColor};
  background-color: ${(props) => props.appConfig.minorColor};
  border-bottom: 0.2vh solid ${(props) => props.appConfig.borderColor};
  height: 3.4vh;
  align-items: center;
  padding-left: 12.8vw;
  display: grid;
  grid-template-columns: 1.75fr 0.75fr 0.4fr;
  gap: 1.1vw;

  p {
    font-size: 1.5vh;
    font-weight: normal;
    margin: 0;
  }
`

export const StyledHearingInfo = styled.div`
  box-sizing: border-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;

  h2 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    display: -webkit-box;
    font-size: 1.5vh;
    font-weight: normal;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    align-self: center;
  }
`

export const StyledHearingDate = styled.div`
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding: 0 1vw;
`
export const StyledHearingTime = styled.div`
  line-clamp: 1;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`
